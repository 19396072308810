import { FC } from 'react';
import { FormType } from '../../../../models/FormTypes';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import ActionEditorBase from '../ActionEditorBase';

const LinkEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, form } = props;
  return (
    <div data-cy="link-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default LinkEditor;
